<template>
	<div class="orderinfo">
		<div class="tofilm-top">
			<div class="tofilm-title">
				<div class="title">确认订单</div>
				<div class="title"></div>
				<!-- <div class="anniu"><el-button type="primary" @click="onpayorder(orderInfoData)">立即付款</el-button></div> -->
			</div> 
			<div class="tofilm-prompt">
				<!-- <div class="text">实拍下单工作时间：周一到周五9:30 - 12:30  14:00 - 18:30</div> -->
			</div>
		</div>
		<div class="saomainfo">
			<div class="wenzi">
				<div class="text">实拍订单号：{{orderInfoData.order_num}}</div>
				<div class="text" v-if="payment_method == 1">订单金额：<span class="jine">￥{{orderInfoData.cost}}</span></div>
				<div class="text" v-else>订单需要积分：<span class="jine">{{orderInfoData.integral}}</span></div>
				<div class="text" v-if="!_isMobile && payment_method == 1">订单付款剩余时间：{{entermin}}分钟 {{entersec}}秒</div>
				<div class="text">下单时间：{{orderInfoData.updated_at}}</div>
				<div class="text" style="display:block" v-if="orderInfoData.tab == 3"><span style="color:#f00;font-size:16px">注意：</span>配音，文案，剪辑都是我们这边负责，预计3个工作日能出视频，成品除不过审因素外无法修改。为了视频效果会有部分混剪</div>
				<div class="text" style="display:block" v-if="orderInfoData.tab == 1"><span style="color:#f00;font-size:16px">注意：</span>配音，文案，剪辑都是我们这边负责，预计3个工作日能出视频，成品除不过审因素外无法修改。</div>
				<div class="text" style="display:block" v-if="orderInfoData.tab == 2"><span style="color:#f00;font-size:16px">注意：</span>拍摄是我们这边负责，预计3个工作日内完成，完成后会以邮箱的形式发您</div>
			</div>
			<div class="zxzhifu" v-if="payment_method == 1">
				<div class="weixinimg" v-if="_isMobile">
					<div class="text"><el-button type="success" icon="iconfont icon-xiaohao" @click="onClickJsapi">点击支付</el-button></div>
				</div>
				<div class="weixinimg" v-else>
					<img :src="'data:image/jpg;base64,' + zhifuimg" alt="">
					<div class="text"><i class="iconfont icon-weixin"></i>微信支付</div>
				</div>
			</div>
			<div v-else class="jfzhifu">
				<div class="jifen">
					<div class="text"><el-button type="success" icon="iconfont icon-xiaohao" @click="onClickByIntegral">点击积分支付</el-button></div>
				</div>
			</div>
		</div>
		<div class="orderinfo-bottom">
			
			<!-- <div class="dan">
				<div class="title">下单时间：</div>
				<div class="text">{{orderInfoData.updated_at}}</div>
			</div> -->
			<!-- <div class="dan">
				<div class="title">订单状态：</div>
				<div class="text">{{orderInfoData.status == 1 ? '未付款' : orderInfoData.status == 2 ? '拍摄中' : orderInfoData.status == 3 ? '拍摄中' : orderInfoData.status == 4 ? '已完成' : orderInfoData.status == 5 ? '已退款' : orderInfoData.status == 6 ? '订单失效' : ''}}</div>
				<div class="text"><el-tag v-if="orderInfoData.status == 1" type="danger" effect="dark" @click="onpayorder(orderInfoData)">付款</el-tag></div>
			</div> -->
			<!-- <div class="dan">
				<div class="title">订单金额：</div>
				<div class="text" style="color:#f00">￥{{orderInfoData.cost}}</div>
			</div> -->
			<div class="dan">
				<div class="title">产品类型：</div>
				<div class="text">{{orderInfoData.classify_child}}</div>
			</div>
			<div class="dan">
				<div class="title">拍摄类型：</div>
				<div class="text">{{orderInfoData.shoot_type}}</div>
			</div>
			<div class="dan">
				<div class="title">秒数：</div>
				<div class="text">{{orderInfoData.second_num}}秒</div>
			</div>
			<div class="dan">
				<div class="title">镜头数：</div>
				<div class="text">{{orderInfoData.shot_num}}个</div>
			</div>
			<div class="dan">
				<div class="title">配音师：</div>
				<div class="text">{{orderInfoData.mixer}}</div>
			</div>
			<div class="dan">
				<div class="title">实拍占比：</div>
				<div class="text">{{orderInfoData.will_share}}</div>
			</div>
			
			<div class="dan">
				<div class="title">是否遮盖商标：</div>
				<div class="text">{{orderInfoData.is_brand}}</div>
			</div>
			<div class="dan">
				<div class="title">是否加水印：</div>
				<div class="text">{{orderInfoData.is_watermark}}</div>
			</div>
			<div class="dan">
				<div class="title">视频格式：</div>
				<div class="text">{{orderInfoData.model_format}}</div>
			</div>

			<div class="dan">
				<div class="title">产品链接：</div>
				<div class="text">{{orderInfoData.product_link}}</div>
			</div>
			<div class="dan">
				<div class="title">样品寄回：</div>
				<div class="text">{{orderInfoData.address ? orderInfoData.address : '不需要寄回'}}</div>
			</div>
			<div class="dan">
				<div class="title">邮箱地址：</div>
				<div class="text">{{orderInfoData.email}}</div>
			</div>
			<div class="dan">
				<div class="title">文案：</div>
				<div class="text">{{orderInfoData.content}}</div>
			</div>
			<div class="dan">
				<div class="title">视频卖点：</div>
				<div class="text">{{orderInfoData.selling_points}}</div>
			</div>
			
			<div class="dan">
				<div class="title">视频要求：</div>
				<div class="text">{{orderInfoData.ask}}</div>
			</div>
			<div class="dan">
				<div class="title">备注：</div>
				<div class="text">{{orderInfoData.priceandactivity_info}}</div>
			</div>
		</div>
		<Payorder ref="Payorder"></Payorder>
	</div>
</template>

<script>
import Payorder from './payorder.vue'
export default {
	data () {
		return {
			payment_method:this.$route.query.payment_method,//判断进来的是积分支付还是微信
			orderInfoData:{},//详细信息
			dingshi:null,//查询支付接口
			zhifuimg:'',
			zhifumoney:'',
			order_num:'',
			time_expire:'',

			// 倒计时
			enterday:'',
			enterhr:'',
			entermin:'',
			entersec:'',
		}
	},
	components:{
		Payorder,
	},
	mounted(){
		this.order_num = this.$route.query.order_num
		this.onorderinfo()
	},
	//离开页面前 执行
	destroyed(){
		clearInterval(this.dingshi)
	},
	methods: {
		// 调取详细信息接口
		onorderinfo(){
			var param = {
				order_num : this.$route.query.order_num
			}
			this.$service.get(this.$api.getVideoOrderInfo, param, (res)=> {
				if(res.code == '200'){
					this.orderInfoData = res.data
					this.zhifuimg = res.data.img
					this.zhifumoney = res.data.morny
					this.order_num = res.data.order_num
					this.time_expire = res.data.time_expire
					if(!this._isMobile){
						this.onenterQuery()
					}
					this.countdown()
				}
			})
		},
		//跳转列表
		tofilmList(){
			this.$router.push('/tofilm/tofilmList')
		},
		// 确认订单掉支付接口
		onenterQuery(){
			if(this.payment_method == 1){
				this.dingshi = setInterval(() => {
					this.onqueryVideoOrder()
				}, 2000);
			}
		},
		// 去支付
		
		// 查询支付结果
		onqueryVideoOrder(){
			var param = {
				order_num:this.order_num,
			}
			this.$service.post(this.$api.queryVideoOrder,param, (res)=> {
				if(res.code == '200'){
					if(res.data){
						clearInterval(this.dingshi)
						this.$refs.Payorder.onSuccessPay()
						this.$refs.Payorder.enterRouter('/tofilm')
						this.$refs.Payorder.onOrdertext('实拍')
					}
				}
			})
		},	
		onasd(){
			this.$refs.Payorder.onSuccessPay()
			this.$refs.Payorder.enterRouter('/tofilm')
			this.$refs.Payorder.onOrdertext('实拍')
		},
		//倒计时
		countdown () {
			const end = Date.parse(new Date(this.time_expire))
			const now = Date.parse(new Date())
			const msec = end - now
			if(msec<0) return;
			let day = parseInt(msec / 1000 / 60 / 60 / 24)
			let hr = parseInt(msec / 1000 / 60 / 60 % 24)
			let min = parseInt(msec / 1000 / 60 % 60)
			let sec = parseInt(msec / 1000 % 60)
			this.enterday = day
			this.enterhr = hr > 9 ? hr : '0' + hr
			this.entermin = min > 9 ? min : '0' + min
			this.entersec = sec > 9 ? sec : '0' + sec
			const that = this
			if(min>=0 && sec>=0){
				//倒计时结束关闭订单
				if(min==0 && sec==0){

				return
				}
				setTimeout(function () {
				that.countdown()
				}, 1000)
			}
		},
		// 移动端支付点击
		onClickJsapi(){
			if(this._isMobile && this._isMicrom){
				alert('请用微信打开，否则不能在线支付')
			}
			else{
				this.onJsapiZhifu()
			}
		},
		// jsapi移动端支付接口参数收取
		onJsapiZhifu(){
			var param = {
				order_id:this.order_num,
			}
			this.$service.post(this.$api.videoOrderPayByJsapi,param, (res)=> {
				if(res.code == '200'){
					this.$refs.Payorder.onBridgeReady(res.data.data)
					this.$refs.Payorder.enterRouter('/tofilm')
					this.$refs.Payorder.onOrdertext('实拍')
				}
			})
		},
		//积分支付
		onClickByIntegral(){
			var param = {
				order_num:this.order_num,
			}
			this.$service.post(this.$api.pcVideoOrderPayByIntegral,param, (res)=> {
				if(res.code == '200'){
					this.$refs.Payorder.onSuccessPay()
					this.$refs.Payorder.enterRouter('/tofilm')
					this.$refs.Payorder.onOrdertext('实拍')
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.orderinfo{
	.tofilm-top{
		border-bottom: 1px solid #ededed;
		padding-bottom: 5px;
		.tofilm-title{
			display: flex;
			font-weight: 500;
			padding-bottom: 15px;
			.title{
				font-size: 22px;
			}
			.anniu{
				margin-left: 10px;
			}
		}
		.tofilm-prompt{
			display: flex;
			justify-content: space-between;
			.horn{
				padding: 5px 10px;
				background: #fefae9;
				color: #ff9a56;
			}
		}
	}
	.saomainfo{
		background: #f7f7f7;
		padding: 20px;
		display: flex;
		.wenzi{
			width: 700px;
			.text{
				font-size: 16px;
				height: 43px;
				display: flex;
				align-items: center;
				&:first-child{
					margin-top: 0;
				}
				span.jine{
					color: #ff9a56;
					font-size: 34px;
					font-weight: 500;
				}
			}
		}
		.weixinimg{
			width: 180px;
			img{
				width: 180px;
				height: 180px;
			}
			.text{
				text-align: center;
				font-size: 16px;
				margin-top: 10px;
				color: #029a02;
				i{
					margin-right: 10px;
				}
			}
		}
		.jfzhifu{
			display: flex;
			align-items: center;
		}
	}
	.orderinfo-bottom{
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		.dan{
			display: flex;
			padding-right: 20px;
    box-sizing: border-box;
			// height: 40px;
			// align-items: center;
			width: 33.33%;
			border-bottom: 1px solid #f9f9f9;
			padding-top: 15px ;
			padding-bottom: 15px ;
			&.saoma{
				height: 250px;
			}
			.title{
				font-size: 16px;
				color: #333;
				font-weight: 500;
				min-width: 100px;
				img{
					width: 250px;
					height: 250px;
				}
			}
			.text{
				color: #666;
				font-size: 16px;
				&:nth-child(3){
					margin-left: 10px;
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.orderinfo{
		.tofilm-top{
			.tofilm-prompt{
				display: block;
				.text{
					margin-bottom: 10px;
				}
			}
		}
		.saomainfo{
			display: block;
			.wenzi{
				width: auto;
				display: flex;
				flex-wrap: wrap;
				.text{
					height: auto;
					display: block;
					width: 100%;
				}
			}
			.weixinimg{
				margin: 0 auto;
			}
		}
		.orderinfo-bottom{
			display: block;
			.dan{
				width: 100%;
			}
		}
	}
}
</style>
